/* Root styles file */
@import '@npm-bbta/bbog-dig-dt-webcomponents-lib/dist/bdb-webcomponents/bdb-webcomponents.css';

body {
  font-family: 'Roboto', sans-serif;
}

.roboto-regular {
  font-family: Roboto-Regular, 'Helvetica Neue', sans-serif;
}

.roboto-medium {
  font-family: Roboto-Medium, 'Helvetica Neue', sans-serif;
}

.roboto-bold {
  font-family: Roboto-Bold, 'Helvetica Neue', sans-serif;
}

// hide recaptcha badge
.grecaptcha-badge {
  visibility: hidden;
}
